import graphqlQuery from './graphqlQuery'
import GLOBAL_QUERY from 'graphql/global.query'

const defaultSettings = {
  offices: {
    nodes: [
      {
        acfOffices: {
          facebookHandle: '',
          instagramHandle: '',
          twitterHandle: '',
          tiktokHandle: '',
        },
      },
    ],
  },
  mainMenu: {
    nodes: [
      {
        acfMenuItems: {
          menu: {
            nodes: [
              {
                items: {
                  nodes: [
                    { url: '' },
                    { isExternalLink: false },
                    { label: '' },
                    { link: { __typename: '', slug: '' } },
                  ],
                },
              },
            ],
          },
        },
      },
    ],
  },
  footerMenu: {
    nodes: [
      {
        acfMenuItems: {
          menu: {
            nodes: [
              {
                items: {
                  nodes: [
                    { url: '' },
                    { isExternalLink: false },
                    { label: '' },
                    { link: { __typename: '', slug: '' } },
                  ],
                },
              },
            ],
          },
        },
      },
    ],
  },
  globalOptions: {
    acfGlobalOptions: {
      title1: '',
      title2: '',
      models1: [{ id: '', slug: '', title: '' }],
      models2: [{ id: '', slug: '', title: '' }],
    },
  },
}

const getGlobalSettings = async (site) => {
  if (!site) return defaultSettings

  const data = await graphqlQuery(GLOBAL_QUERY, site)
  if (!data) return defaultSettings

  return data
}

export default getGlobalSettings
